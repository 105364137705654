import { gql } from '@apollo/client';
import ConsultantBaseFragment from 'Shared/api/fragments/Consultant/ConsultantBaseFragment';
import ConsultantLevelBaseFragment from 'Shared/api/fragments/ConsultantLevel/ConsultantLevelBaseFragment';
import TimesheetDailyRecordFragment from './TimesheetDailyRecordFragment';


const TimesheetWeeklyRecordFragment = gql`
  fragment TimesheetWeeklyRecordData on TimesheetWeeklyRecord {
    id
    isEditable
    isApproved
    position
    periodStartDate
    actualUnits
    plannedUnits
    reason
    comment
    dailyRecords {
      ...TimesheetDailyRecordData
    }
  }
  ${TimesheetDailyRecordFragment}
`;

const ProjectTimesheetAnnotationFragment = gql`
  fragment ProjectTimesheetAnnotationData on ProjectTimesheetAnnotation {
    id
    position
    periodStartDate
    periodType
    cost
    revenue
  }
`;

const TimesheetProjectRole = gql`
  fragment TimesheetProjectRoleData on ProjectRole {
    id
    role
    costRate
    clientRate
    grossMarginPct
    revenue
    cost
    grossMargin
    startDate
    endDate
    unitType
    unitsPerWeek
    units
    isManualCollected
    consultant {
      ...ConsultantBaseData
      level {
        ...ConsultantLevelBase
      }
      isDisabled
    }
    currency
    actualUnits
    plannedUnits
  }
  ${ConsultantLevelBaseFragment}
  ${ConsultantLevelBaseFragment}
`;

const ProjectTimesheetFragment = gql`
  fragment ProjectTimesheetData on ProjectTimesheet {
    id
    role {
      ...TimesheetProjectRoleData
    }
    actualUnits
    plannedUnits
    forecastedUnits
    remainingUnits
    deltaUnits
    timesheet {
      ...TimesheetWeeklyRecordData
    }
  }
  ${ConsultantBaseFragment}
  ${TimesheetWeeklyRecordFragment}
  ${TimesheetProjectRole}
`;

const ProjectTimesheetPlannerBundleFragment = gql`
  fragment ProjectTimesheetPlannerBundleData on ProjectTimesheetPlannerBundle {
    id
    annotations {
      ...ProjectTimesheetAnnotationData
    }
    timesheet {
      ...ProjectTimesheetData
    }
  }
  ${ProjectTimesheetAnnotationFragment}
  ${ProjectTimesheetFragment}
`;

export default ProjectTimesheetPlannerBundleFragment;
