import {
  ContractStatus,
  ProjectDeliverableStage,
  UpdateContractStatus,
  OnboardingRequirements,
} from '__generated__/graphql/types';
import { TypedTypePolicies } from '__generated__/graphql/apollo-client-helpers';
import { convertEnumToArray } from 'Shared/utils';
import { CONSULTANT_ONBOARDING_REQUIREMENTS_SORT as onboardingSort } from 'WorkspacePortal/constants/index';
import { IActiveWorkspaceStorage } from 'Shared/services/activeWorkspace/ActiveWorkspaceStorage';

export type Props = {
  activeWorkspaceStorage: IActiveWorkspaceStorage;
};

function createTypePolicies({
  activeWorkspaceStorage,
}: Props): TypedTypePolicies {
  const typePolicies: TypedTypePolicies = {
    AuthPayload: {
      keyFields: [],
    },
    AvailableWorkspace: {
      fields: {
        active: {
          read(active, options) {
            return options.readField('id') === activeWorkspaceStorage.id;
          },
        },
      },
    },
    Project: {
      // be carefull when changing it
      // we retrieve project entity from cache using these keyFields
      // see CachedDataRepository.getProject
      keyFields: ['id', 'scenario', ['slug'] as any],
    },
    WorkspaceConsultant: {
      fields: {
      }
    },
    ConsultantFunctionalRole: {
      keyFields: ['name']
    },
    ConsultantIndustry: {
      keyFields: ['name']
    },
    Query: {
      fields: {
        contractStatuses: {
          read() {
            return convertEnumToArray<ContractStatus>(ContractStatus);
          },
        },
        changeableContractStatuses: {
          read() {
            return convertEnumToArray<UpdateContractStatus>(UpdateContractStatus);
          },
        },
        projectDeliverableStages: {
          read() {
            return convertEnumToArray<ProjectDeliverableStage>(ProjectDeliverableStage);
          },
        },
        allConsultantOnboardingRequirements: {
          read() {
            return convertEnumToArray<OnboardingRequirements>(OnboardingRequirements)
              .sort((a, b) => (onboardingSort[a.value] - onboardingSort[b.value]));
          },
        },
      },
    },
  };
  return typePolicies;
}

export default createTypePolicies;
