import { gql } from '@apollo/client';
import CommonProjectFieldsFragment from 'WorkspacePortal/api/fragments/Project/CommonProjectFieldsFragment';
import ConsultantGroupBaseFragment from 'Shared/api/fragments/ConsultantGroup/ConsultantGroupBaseFragment';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';
import UserFragment from 'Shared/api/fragments/User/UserFragment';

const ProjectsTableScenarioFragment = gql`
  fragment ProjectsTableScenarioData on ProjectScenario {
    ...ProjectScenarioRequiredData
    revenue
    grossMargin
    grossMarginPct
    startDate
    endDate
    totalInvoiced
    totalUninvoiced
    lastInvoiced
    discount
  }
  ${ProjectScenarioRequiredDataFragment}
`;

const ProjectsFragment = gql`
  fragment ProjectsData on Project {
    client {
      id
      name
      clientBillingAddress
    }
    scenario {
      ...ProjectsTableScenarioData
    }
    consultantGroups {
      ...ConsultantGroupBaseData
    }
    ...CommonProjectFields
  }
  ${UserFragment}
  ${ConsultantGroupBaseFragment}
  ${CommonProjectFieldsFragment}
  ${ProjectsTableScenarioFragment}
`;

export default ProjectsFragment;
