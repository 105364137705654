import { gql } from '@apollo/client';
import TimesheetDailyRecordFragment from 'WorkspacePortal/api/fragments/ProjectTimesheetPlannerBundle/TimesheetDailyRecordFragment';

const EngagementRoleStatusFragment = gql`
  fragment EngagementRoleStatusData on EngagementRoleStatus {
    status
    clientName
    projectName
    roleName
    roleId
    engagementLeadEmail
    engagementLeadName
    plannedUnits
    actualUnits
    unitType
    reason
    comment
    dailyRecords {
      ...TimesheetDailyRecordData
    }
  }
  ${TimesheetDailyRecordFragment}
`;

const EngagementStatusFragment = gql`
  fragment EngagementStatusData on EngagementStatus {
    consultantName
    workspaceIdentifier
    weekName
    roles {
      ...EngagementRoleStatusData
    }
  }
  ${EngagementRoleStatusFragment}
`;

export default EngagementStatusFragment;
