import { gql } from '@apollo/client';
import CommonProjectFieldsFragment from 'WorkspacePortal/api/fragments/Project/CommonProjectFieldsFragment';
import ClientFragment from 'WorkspacePortal/api/fragments/Client/ClientFragment';
import UserFragment from 'Shared/api/fragments/User/UserFragment';
import ProjectScenarioFullFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioFullFragment';
import CurrencyRateFragment from 'Shared/api/fragments/Currency/CurrencyRateFragment';

const ProjectFragment = gql`
  fragment ProjectData on Project {
    client {
      ...ClientData
    }
    previousStage
    locked
    updatedAt
    updatedByUser {
      ...UserData
    }
    scenario {
      ...ProjectScenarioFullData
    }
    winProbability
    currencyRates {
      ...CurrencyRateData
    }
    ...CommonProjectFields
  }
  ${UserFragment}
  ${ClientFragment}
  ${CommonProjectFieldsFragment}
  ${ProjectScenarioFullFragment}
  ${CurrencyRateFragment}
`;

export default ProjectFragment;
